<template>
<div class="flex items-center justify-end">
    <div id="notification-toast"  class="toast-wrapper top-end">
    <div class="toast fade">
      <div class="notification">
            <div class="notification-content">
                <div class="mr-3">
                    <span class="text-2xl text-yellow-400">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path>
                        </svg>
                    </span>
                </div>
                <div class="mr-4">
                    <div class="notification-title">Warning</div>
                    <div class="notification-description">
                        {{ toastmsg }}
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {

    props: {
        
        toastmsg: {
            type: [String, Boolean, Number],
            default: ''
        },
    },

    computed:{
        ...mapGetters(['loading'])
    },

}
</script>