export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  state: {
    user: "",
    users: "",
  },

  getters: {
    user: (state) => state.user,
    users: (state) => state.users,
  },

  mutations: {
    setuser: (state, user) => (state.user = user),
    setusers: (state, users) => (state.users = users),
  },

  actions: {
  

    async get_users({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_users");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/levels?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setusers", response.data.data);
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
  },
};
