import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './axios'
import "@/assets/app_style.css"
import "@/assets/app_style_dark.css"
import "@/assets/app_script.js"

//compoents
import loadingbtn from '@/views/comps/loading_button.vue'
import loadingdata from '@/views/comps/loading_data.vue'
import warningtoast from '@/views/comps/warning_toast.vue'
import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"
library.add(far);
library.add(fab);
library.add(fas);
dom.watch();

const app = createApp(App)
app.config.globalProperties.$filters = {
    timeAgo(date) {
    return moment(date).fromNow()
    },

    dateonly(date) {
        return moment(date).format("DD/MM/YYYY")
    },

    dateonlywithday(date) {
        return moment(date).format("DD/MM/YYYY - dddd")
    },


    datetime(date){
        return moment(date).format("YYYY-MM-DD HH:mm:ss")
    },

    timeonly(date){
        return moment(date).format("HH:mm:ss")
    },

    remove_dash(value){
        if (!value) return ''
        return value.replace(/_/g, ' ') 
    },

    currency(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
}

app.component("font-awesome-icon", FontAwesomeIcon).component("warningtoast", warningtoast).component("loadingbtn", loadingbtn).component("loadingdata", loadingdata).use(store).use(Toast).use(router).mount('#app')
