export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  state: {
    obituary: "",
    obituaries: "",
  },

  getters: {
    obituary: (state) => state.obituary,
    obituaries: (state) => state.obituaries,
  },

  mutations: {
    setobituary: (state, obituary) => (state.obituary = obituary),
    setobituaries: (state, obituaries) => (state.obituaries = obituaries),
  },

  actions: {

    async create_obituary({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "create_obituary");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/obituaries/create", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
              commit("setobituary", response.data.res);
              
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },


    async get_obituaries({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_obituaries");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/obituaries?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
              commit("setobituaries", response.data.res);
              
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async get_obituary_details({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_obituary_details");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/obituaries/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
              commit("setobituary", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async update_obituary({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "update_obituary");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/obituaries/update/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
              commit("setobituary", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async change_obituary_status({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "change_obituary_status");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/obituaries/status/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
              commit("setobituary", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },    
    async create_obituary_new_family({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "create_obituary_new_family");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/obituaries/family/create/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
              commit("setobituary", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
    async delete_obituary_family({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "delete_obituary_family");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/obituaries/family/delete/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
              commit("setobituary", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },


    async create_burial_image({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "create_burial_image");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/obituaries/burial/create/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
              commit("setobituary", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
    async delete_burial_image({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "delete_burial_image" + id);
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/obituaries/burial/delete/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
              commit("setobituary", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
  },
};
