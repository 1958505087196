export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  state: {
    location: "",
    locations: "",
  },

  getters: {
    location: (state) => state.location,
    locations: (state) => state.locations,
  },

  mutations: {
    setlocation: (state, location) => (state.location = location),
    setlocations: (state, locations) => (state.locations = locations),
  },

  actions: {
  

    async create_location({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "create_location");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/locations/create", data)
          .then((response) => {
            commit("setloader", false);
            if(response.data) {
                commit("setlocations", response.data.res);
              }
              resolve(response);
              
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },


    async get_locations({ commit }, { data, page }) {
        commit("seterror", "");
        commit("setmsg", "");
        commit("setloader", "get_locations");
        return new Promise((resolve, reject) => {
          axios
            .post("/admin/locations?page=" + page, data)
            .then((response) => {
              commit("setloader", false);
              if (response.data.res) {
                commit("setlocations", response.data.res);
                resolve(response);
              }
            })
            .catch((error) => {
              commit("setloader", false);
              if (error.response) {
                if (error.response.data) {
                  if (error.response.data.msg) {
                    commit("seterror", error.response.data.msg);
                    toast.error(error.response.data.msg)
                  } else if (error.response.data.message) {
                    commit("seterrors", error.response.data.message);
                  }
                }
              }
              reject(error);
            });
        });
      },
    
    async update_location({ commit }, { data, id }) {
        commit("seterror", "");
        commit("setmsg", "");
        commit("setloader", "update_location");
        return new Promise((resolve, reject) => {
        axios.post("/admin/locations/update/" + id, data)
            .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
                commit("setlocations", response.data.res);
                resolve(response);
            }
            })
            .catch((error) => {
            commit("setloader", false);
            if (error.response) {
                if (error.response.data) {
                if (error.response.data.msg) {
                    commit("seterror", error.response.data.msg);
                    toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                    commit("seterrors", error.response.data.message);
                }
                }
            }
            reject(error);
            });
        });
    },

    async update_location_status({ commit }, { data, id }) {
        commit("seterror", "");
        commit("setmsg", "");
        commit("setloader", "update_location_status");
        return new Promise((resolve, reject) => {
        axios.post("/admin/locations/status/" + id, data)
            .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
                commit("setlocations", response.data.res);
            }
            resolve(response);
            })
            .catch((error) => {
            commit("setloader", false);
            if (error.response) {
                if (error.response.data) {
                if (error.response.data.msg) {
                    commit("seterror", error.response.data.msg);
                    toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                    commit("seterrors", error.response.data.message);
                }
                }
            }
            reject(error);
            });
        });
    },

    async delete_location({ commit }, { data, id }) {
        commit("seterror", "");
        commit("setmsg", "");
        commit("setloader", "delete_location");
        return new Promise((resolve, reject) => {
        axios.post("/admin/locations/delete/" + id, data)
            .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
                commit("setlocations", response.data.res);
            }
            resolve(response);
            })
            .catch((error) => {
            commit("setloader", false);
            if (error.response) {
                if (error.response.data) {
                if (error.response.data.msg) {
                    commit("seterror", error.response.data.msg);
                    toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                    commit("seterrors", error.response.data.message);
                }
                }
            }
            reject(error);
            });
        });
    },

      
  },
};
