export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  state: {
    family: "",
    familys: "",
  },

  getters: {
    family: (state) => state.family,
    familys: (state) => state.familys,
  },

  mutations: {
    setfamily: (state, family) => (state.family = family),
    setfamilys: (state, familys) => (state.familys = familys),
  },

  actions: {
 

    async get_familys({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_familys");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/levels?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setfamilys", response.data.data);
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
    // creating family members
    async create_family({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "create_family");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/users/create", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setusers", response.data.data);
              toast.success("obituary Created Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async update_family({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "update_family");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/users/create", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setusers", response.data.data);
              toast.success("obituary Created Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async delete_family({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "delete_family");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/users/create", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setusers", response.data.data);
              toast.success("obituary Created Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },


  },
};
